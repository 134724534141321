<template>
  <transition appear>
    <div class="flex">
      <div class="sidebar bg-lightGrey py-10 px-5">
        <div class="my-5 flex justify-start items-center cursor-pointer">
          <span
            @click="goBack"
            class="material-icons rounded-full bg-teal text-white"
          >
            chevron_left
          </span>
          <router-link
            class="font-bold text-teal mx-2"
            :to="{ name: `re-locums` }"
          >
            Back To Dashboard
          </router-link>
        </div>
        <div
          @click="usersFunc"
          class="filter-container py-3 border-b-2 border-gray-300"
        >
          <div class="filter-header flex items-center">
            <span class="material-icons text-1xl text-teal font-bold">
              groups
            </span>
            <h2
              class="
                filter-heading
                text-grey
                font-bold
                hover:text-darkGrey
                cursor-pointer
              "
              :class="{ clickClass: cardsHeading === 'Users:' }"
            >
              &nbsp;Users
            </h2>
          </div>
        </div>
        <div
          @click="locumsFunc"
          class="filter-container py-3 border-b-2 border-gray-300"
        >
          <div class="filter-header flex items-center">
            <span class="material-icons text-1xl text-teal font-bold">
              group
            </span>
            <h2
              class="
                filter-heading
                text-grey
                font-bold
                hover:text-darkGrey
                cursor-pointer
              "
              :class="{ clickClass: cardsHeading === 'Locums:' }"
            >
              &nbsp;Locums
            </h2>
          </div>
        </div>
        <div
          @click="blacklistFunc"
          class="filter-container py-3 border-b-2 border-gray-300"
        >
          <div class="filter-header flex items-center">
            <span class="material-icons text-1xl text-teal font-bold">
              block
            </span>
            <h2
              class="
                filter-heading
                text-grey
                font-bold
                hover:text-darkGrey
                cursor-pointer
              "
              :class="{ clickClass: cardsHeading === 'Blacklisted Locums:' }"
            >
              &nbsp;Blacklisted Locums
            </h2>
          </div>
        </div>
        <div class="filter-container py-3 border-b-2 border-gray-300">
          <div class="filter-header flex justify-between items-center">
            <h2 class="filter-heading text-grey font-bold hover:text-darkGrey">
              <span class="material-icons text-1xl text-teal font-bold">
                filter_list
              </span>
              Filters
            </h2>
            <div class="flex items-center gap-x-3">
              <span
                class="
                  material-icons
                  text-3xl
                  cursor-pointer
                  text-teal
                  font-bold
                  filter-dropdown-icon
                "
                :class="{ 'filter-up': filtersExpanded }"
                @click="() => (filtersExpanded = !filtersExpanded)"
              >
                expand_more
              </span>
            </div>
          </div>
          <transition name="fade" mode="ease">
            <div class="flex flex-col" v-if="filtersExpanded">
              <div>
                <va-button
                  @click="applyFilters"
                  :rounded="false"
                  outline
                  color="#00b5b5"
                  class="mb-2 w-full"
                  >Apply Filters</va-button
                >
              </div>
              <div class="filter-container p-3 border-b-2 border-gray-300">
                <div class="filter-header flex justify-between items-center">
                  <h2 class="filter-heading text-grey font-bold">Location</h2>
                  <div class="flex items-center gap-x-3">
                    <button
                      @click="toggleSelectAllLocations"
                      class="
                        rounded-xl
                        px-3
                        border-2 border-teal
                        text-teal
                        font-bold
                      "
                    >
                      {{
                        this.filterLocations.length === this.locations.length &&
                        this.locations.length > 0
                          ? "Un-Select All"
                          : "Select ALl"
                      }}
                    </button>
                    <span
                      class="
                        material-icons
                        text-3xl
                        cursor-pointer
                        text-teal
                        font-bold
                        filter-dropdown-icon
                      "
                      :class="{ 'filter-up': locationFilterExpanded }"
                      @click="
                        () => (locationFilterExpanded = !locationFilterExpanded)
                      "
                    >
                      expand_more
                    </span>
                  </div>
                </div>
                <transition name="fade" mode="ease">
                  <div class="flex flex-col" v-if="locationFilterExpanded">
                    <div
                      v-for="location in locations"
                      :key="location.id"
                      class="mb-1 mt-3 flex items-center"
                    >
                      <span
                        class="
                          flex
                          items-center
                          jsutify-center
                          border-2
                          rounded-sm
                          border-teal
                          w-5
                          h-5
                          z-index-999
                          overflow-hidden
                        "
                      >
                        <input
                          type="checkbox"
                          @change="locationToggle"
                          v-model="filterLocations"
                          :value="location.id"
                          name="locations[]"
                          class="
                            cursor-pointer
                            form-tick
                            appearance-none
                            bg-white bg-check
                            h-4
                            w-4
                            border border-grey
                            checked:bg-teal checked:border-transparent
                            focus:outline-none
                          "
                        />
                      </span>
                      <h3
                        v-if="location.practice_name[0]"
                        class="text-grey font-bold mx-2"
                      >
                        {{
                          location.practice_name[0].toUpperCase() +
                          location.practice_name.slice(1)
                        }}
                      </h3>
                    </div>
                  </div>
                </transition>
              </div>
              <div class="filter-container p-3">
                <div class="filter-header flex justify-between items-center">
                  <h2 class="filter-heading text-grey font-bold">Role</h2>
                  <div class="flex items-center gap-x-3">
                    <button
                      @click="toggleSelectAllRoles"
                      class="
                        rounded-xl
                        px-3
                        border-2 border-teal
                        text-teal
                        font-bold
                      "
                    >
                      {{
                        this.filterRoles.length === this.roles.length &&
                        this.roles.length > 0
                          ? "Un-Select All"
                          : "Select ALl"
                      }}
                    </button>
                    <span
                      class="
                        material-icons
                        text-3xl
                        cursor-pointer
                        text-teal
                        font-bold
                        filter-dropdown-icon
                      "
                      :class="{ 'filter-up': roleFilterExpanded }"
                      @click="() => (roleFilterExpanded = !roleFilterExpanded)"
                    >
                      expand_more
                    </span>
                  </div>
                </div>
                <transition name="fade" mode="ease">
                  <div class="flex flex-col" v-if="roleFilterExpanded">
                    <div
                      v-for="role in roles"
                      :key="role.id"
                      class="mb-1 mt-3 flex items-center"
                    >
                      <span
                        class="
                          flex
                          items-center
                          jsutify-center
                          border-2
                          rounded-sm
                          border-teal
                          w-5
                          h-5
                          z-index-999
                          overflow-hidden
                        "
                      >
                        <input
                          @change="roleToggle"
                          v-model="filterRoles"
                          :value="role.id"
                          name="roles[]"
                          type="checkbox"
                          class="
                            cursor-pointer
                            form-tick
                            appearance-none
                            bg-white bg-check
                            h-4
                            w-4
                            border border-grey
                            checked:bg-teal checked:border-transparent
                            focus:outline-none
                          "
                        />
                      </span>
                      <h3 v-if="role.name[0]" class="text-grey font-bold mx-2">
                        {{ role.name[0].toUpperCase() + role.name.slice(1) }}
                      </h3>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </transition>
        </div>
        <!-- <div class="filter-container py-3 border-b-2 border-gray-300">
                    <div class="filter-header flex justify-between items-center">
                        <h2 class="filter-heading text-grey font-bold">
                            Locum Status
                        </h2>
                        <div class="flex items-center gap-x-3">
                            <button class="rounded-xl px-3 border-2 border-teal text-teal font-bold">
                                Select All
                            </button>
                            <span class="material-icons text-3xl cursor-pointer   text-teal font-bold filter-dropdown-icon " :class="{'filter-up': statusFilterExpanded}" @click="()=>statusFilterExpanded=!statusFilterExpanded">
                                expand_more
                            </span>
                        </div>
                    </div>
                    <transition name="fade" mode="ease">
                    <div class="flex flex-col" v-if="statusFilterExpanded">
                        <div class="mb-1 mt-3 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                Currentlt on Rota
                            </h3>
                        </div>
                        <div class="mb-1 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey  checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                Previously Hired
                            </h3>
                        </div>
                        <div class="mb-1 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                New to Sysytem
                            </h3>
                        </div>
                        <div class="mb-1 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                Blocklisted
                            </h3>
                        </div>
                    </div>
                 </transition>
                </div>
                <div class="filter-container py-3 border-b-2 border-gray-300">
                    <div class="filter-header flex justify-between items-center">
                        <h2 class="filter-heading text-grey font-bold">
                            Priviledges
                        </h2>
                        <div class="flex items-center gap-x-3">
                            <button class="rounded-xl px-3 border-2 border-teal text-teal font-bold">
                                Select All
                            </button>
                            <span class="material-icons text-3xl cursor-pointer   text-teal font-bold filter-dropdown-icon " :class="{'filter-up': priviledgesFilterExpanded}" @click="()=>priviledgesFilterExpanded=!priviledgesFilterExpanded">
                                expand_more
                            </span>
                        </div>
                    </div>
                    <transition name="fade" mode="ease">
                    <div class="flex flex-col" v-if="priviledgesFilterExpanded">
                        <div class="mb-1 mt-3 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                Rushden
                            </h3>
                        </div>
                        <div class="mb-1 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey  checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                County
                            </h3>
                        </div>
                        <div class="mb-1 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                Surgery 2
                            </h3>
                        </div>
                        <div class="mb-1 flex items-center">
                            <span class="flex items-center jsutify-center border-2 rounded-sm border-teal w-5 h-5 z-index-999 overflow-hidden">
                                <input type="checkbox" class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-grey checked:bg-teal checked:border-transparent focus:outline-none">
                            </span>
                            <h3 class="text-grey font-bold mx-2">
                                Surgery 3
                            </h3>
                        </div>
                    </div>
                 </transition>
                </div> -->
      </div>
      <div
        class="
          profiles-container
          my-3
          mr-10
          py-10
          px-5
          lg:px-28
          bg-white
          shadow-lg
          rounded-r-xl
          w-full
        "
      >
        <div class="profiles-header flex justify-between">
          <h2 class="font-bold text-grey">{{ cardsHeading }}</h2>
          <div class="operations flex items-center gap-x-2">
            <button
              class="rounded-xl px-3 border-2 border-teal text-teal font-bold"
            >
              Select All
            </button>
            <p class="font-bold text-grey">
              Sort By: Alphabetical /
              <span class="text-grey font-normal">Privileges</span>
            </p>
          </div>
          <div class="flex items-center gap-x-2">
            <!-- <span class="material-icons text-teal text-4xl">
                            add_circle
                        </span> -->
            <!-- <div class="relative inline-block text-left">
                            <div>
                                <button @click="toggleDropdown" type="button" class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white  text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                    <span class="material-icons">
                                        apps
                                    </span>
                                </button>
                            </div>

                           
                                Dropdown menu, show/hide based on menu state.

                                Entering: "transition ease-out duration-100"
                                From: "transform opacity-0 scale-95"
                                To: "transform opacity-100 scale-100"
                                Leaving: "transition ease-in duration-75"
                                From: "transform opacity-100 scale-100"
                                To: "transform opacity-0 scale-95"
                         
                            <div v-if="dropdown" class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                                <div class="py-1" role="none">
                                Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700"
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                                <a href="#" class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a>
                                
                                </div>
                            </div>
                        </div> -->
          </div>
        </div>
        <!-- Base Spinner -->
        <div v-if="spinnerFlag" class="align-center pt-20">
          <base-spinner> </base-spinner>
        </div>
        <!-- Profile Cards -->
        <div
          v-if="!spinnerFlag"
          class="profile-cards my-5 flex flex-wrap justify-center gap-5"
          :key="refreshProfiles"
        >
          <div v-for="user in profiles" :key="user.id" class="card">
            <div
              class="
                front-and-back
                profile-card
                w-64
                rounded-xl
                p-8
                pl-0.5
                relative
                text-grey
                bg-lightGrey
              "
              :class="[user.active ? 'rotateClass' : '']"
            >
              <div
                v-if="user.active && cardsHeading === 'Blacklisted Locums:'"
                class="back"
              >
                <div class="z-10 notesList">
                  <div class="font-bold">Reason for Blacklisting:</div>
                  <div><b>&#9745;</b> {{ user.blacklist_reason }}</div>
                  <div class="font-bold pt-2">Notes:</div>
                  <div v-for="note in user.locum_notes" :key="note.id">
                    <b>&#9745;</b>{{ note.note }}
                  </div>
                  <div v-if="user.locum_notes.length === 0">Unavaiable</div>
                </div>
              </div>
              <div
                v-if="
                  user.active &&
                  (cardsHeading === 'Users:' || cardsHeading === 'Locums:')
                "
                class="back z-10 pt-2px"
              >
                <div class="font-bold">Notes:</div>
                <div class="notesList">
                  <div v-for="note in user.locum_notes" :key="note.id">
                    <b>&#9745;</b>{{ note.note }}
                  </div>
                  <div v-if="user.locum_notes.length === 0">Unavaiable</div>
                </div>
              </div>
              <div v-if="!user.active" class="front">
                <div
                  class="rounded-full w-32 h-32 border-2 border-teal p-2 ml-12"
                >
                  <span
                    class="
                      material-icons
                      w-full
                      h-full
                      flex
                      items-center
                      text-white text-8xl
                      pl-2
                      rounded-full
                      bg-orange
                    "
                  >
                    person_filled
                  </span>
                </div>
                <h3 class="font-bold my-4">
                  {{ user.profile?.first_name + " " + user.profile?.last_name }}
                </h3>
                <p>
                  Role:
                  <span
                    v-if="user.position_summary.job_title"
                    class="font-bold"
                    >{{ user.position_summary.job_title.toUpperCase() }}</span
                  >
                </p>
                <p>
                  Location:
                  <span
                    v-if="user.practices[0]?.practice_name"
                    class="font-bold"
                    >{{ user.practices[0].practice_name.toUpperCase() }}</span
                  ><span
                    v-if="!user.practices[0]?.practice_name"
                    class="font-bold"
                    >Unavailable</span
                  >
                </p>
                <p>Qualifications:</p>
                <p class="font-bold">Injections, SMEAR</p>

                <p>
                  Previous Rate:
                  <span v-if="user.locum_sessions[0]?.rate" class="font-bold">
                    £{{ user.locum_sessions[0]?.rate }}/{{
                      user.locum_sessions[0]?.unit
                    }}</span
                  ><span v-else class="font-bold">Unavailable</span>
                </p>
                <div
                  class="left-icons absolute top-3 left-2 flex flex-col gap-y-1"
                >
                  <span
                    class="
                      cursor-pointer
                      material-icons
                      text-teal text-2xl
                      w-9
                      flex
                      items-center
                      justify-center
                      border-2 border-teal
                      rounded-full
                    "
                  >
                    priority_high
                  </span>
                </div>
                <div class="left-icons absolute top-3 right-2">
                  <span
                    @click="toggleDropdown(user.id)"
                    class="
                      cursor-pointer
                      material-icons
                      text-teal text-2xl
                      w-9
                      flex
                      items-center
                      justify-center
                      border-2 border-teal
                      rounded-full
                    "
                  >
                    more_vert
                  </span>
                  <div
                    v-if="dropdown && dropdownId == user.id"
                    class="
                      absolute
                      right-0
                      z-999
                      mt-2
                      w-56
                      origin-top-right
                      rounded-md
                      bg-white
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                    "
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="text-left py-1" role="none">
                      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                      <a
                        v-if="cardsHeading == 'Users:'"
                        @click="toggleDropdownSecondary(user.id)"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                        >&nbsp;&nbsp;&nbsp;&nbsp;Invite User For Session ></a
                      >
                      <a
                        v-if="cardsHeading == 'Locums:'"
                        @click="toggleDropdownSecondary(user.id)"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                        >Add Locum To Session ></a
                      >
                      <a
                        v-if="cardsHeading == 'Locums:'"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-1"
                        @click="notesForUserCheck(user.id)"
                        >Add Notes For Locum</a
                      >
                      <a
                        v-if="cardsHeading == 'Locums:'"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-2"
                        @click="removeUserAsLocum(user.id)"
                        >Remove As Locum</a
                      >
                      <a
                        v-if="cardsHeading == 'Locums:'"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-2"
                        @click="openBlacklistReasonDialog(user.id)"
                        >Blacklist Locum</a
                      >
                      <a
                        v-if="cardsHeading == 'Blacklisted Locums:'"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-2"
                        @click="removeFromBlacklist(user.id)"
                        >Remove From Blacklisted Locums</a
                      >
                    </div>
                  </div>
                  <div
                    v-if="dropdownSecondary && dropdownId == user.id"
                    class="
                      absolute
                      right-12
                      z-999
                      mt-2
                      w-56
                      origin-top-right
                      rounded-md
                      bg-white
                      shadow-lg
                      ring-1 ring-black ring-opacity-5
                      focus:outline-none
                    "
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div class="text-left py-1" role="none">
                      <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
                      <a
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                        @click="selectSession(user)"
                        >Select Session</a
                      >
                      <a
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-1"
                        >Create New Session</a
                      >
                    </div>
                  </div>
                </div>
                <base-dialog
                  class="
                    left-icons
                    absolute
                    bottom-3
                    left-2
                    flex flex-col
                    gap-y-1
                  "
                >
                  <span
                    class="
                      cursor-pointer
                      material-icons
                      text-teal text-2xl
                      w-9
                      flex
                      items-center
                      justify-center
                      border-2 border-teal
                      rounded-full
                    "
                    @click="rotateFunc(user)"
                  >
                    flip_to_back
                  </span>
                </base-dialog>
              </div>
              <div
                class="
                  left-icons
                  absolute
                  bottom-3
                  left-2
                  flex flex-col
                  gap-y-1
                "
              >
                <span
                  class="
                    cursor-pointer
                    material-icons
                    text-teal text-2xl
                    w-9
                    flex
                    items-center
                    justify-center
                    border-2 border-teal
                    rounded-full
                  "
                  @click="rotateFunc(user)"
                >
                  flip_to_front
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="align-bottom flex justify-center my-5 pt-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="locumProfiles.data?.users?.total"
            @current-change="change"
          >
          </el-pagination>
        </div>
      </div>

      <base-modal
        v-model:show="showSelectSession"
        @confirm="submit"
        :showCancel="false"
        :showConfirm="false"
      >
        <template #title>Select Session</template>
        <template #content>
          <div class="px-5 py-0">
            <div class="rounded-xl w-full -my-3">
              <div
                v-if="sessions.length > 0"
                class="
                  w-full
                  flex-wrap flex flex-col
                  justify-start
                  items-start items-center
                  gap-2
                "
              >
                <div
                  v-for="session in sessions"
                  :key="session.id"
                  class="flex justify-between align-center"
                >
                  <va-accordion>
                    <va-collapse
                      :key="session.id"
                      :header="session.name"
                      style="width: 450px"
                    >
                      <div
                        style="width: 450px"
                        class="
                          session-card
                          border-l-8
                          flex flex-col
                          border-grey-200
                          py-4
                          px-6
                        "
                      >
                        <div class="font-bold flex session-row">
                          <div>
                            <span class="text-grey"
                              >{{ format12(session.start_time) }} -
                              {{ session.end_date }}({{
                                format12(session.end_time)
                              }})</span
                            >
                          </div>
                        </div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Name: </span>
                            <span class="text-grey">
                              {{ session.name }}
                            </span>
                          </div>
                        </div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Location: </span>
                            <span class="text-grey">
                              {{ session.location }}
                            </span>
                          </div>
                        </div>
                        <div class="font-bold flex session-row">
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Rate: </span>
                            <span class="text-grey">
                              £{{ session.rate }}/{{ session.unit }}
                            </span>
                          </div>
                          <div class="flex gap-x-2 w-1/2">
                            <span class="text-teal"> Position: </span>
                            <span v-if="session.locums[0]" class="text-grey">
                              {{ session.locums[0]?.roles[0].name }}
                            </span>
                            <span v-else class="text-grey"> Unavailable </span>
                          </div>
                        </div>
                      </div>
                    </va-collapse>
                  </va-accordion>
                  <va-button
                    v-if="
                      userInvites.findIndex(
                        (invite) =>
                          invite.locum == invitePayload.locum &&
                          invite.session == session.id
                      ) === -1
                    "
                    @click="sendInvite(session.id)"
                    color="#00b5b5"
                    text-color="#fff"
                    class="rounded-none"
                  >
                    Invite User
                    <span
                      class="
                        material-icons-outlined
                        flex
                        align-center
                        text-white
                        font-4xl
                        ml-5
                      "
                    >
                      send
                    </span>
                  </va-button>
                  <va-button
                    v-else
                    color="#b5b5b5"
                    text-color="#fff"
                    class="rounded-none"
                  >
                    Invite Sent
                    <span
                      class="
                        material-icons-outlined
                        flex
                        align-center
                        text-white
                        font-4xl
                        ml-5
                      "
                    >
                      mark_email_read
                    </span>
                  </va-button>
                  <!-- <div v-if="session.status != 1 && loaderFlag == true">
                    <i
                      class="
                        animate-spin
                        material-icons-outlined
                        align-center
                        text-teal
                        h-5
                        w-5
                      "
                    >
                      rotate_right_outlined
                    </i>
                  </div> -->
                </div>

                <!-- <div class="flex gap-x-2">
                                    <span class="text-teal decoration-2 underline">
                                    Show All
                                    </span>
                                </div> -->
              </div>
            </div>
            <div class="align-bottom flex justify-center my-5 pt-2">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="sessionsResponse.total"
                @current-change="changeSessionsPage"
              >
              </el-pagination>
            </div>
          </div>
        </template>
      </base-modal>

      <base-modal
        v-model:show="showNotes"
        @confirm="submitNotes"
        :showCancel="true"
        :showConfirm="true"
      >
        <template #title>Enter Notes for User</template>
        <template #content>
          <div class="w-11.5/12 m-auto">
            <div v-for="(item, index) in notes" :key="index" class="sm:my-1">
              <input
                placeholder="Enter note:"
                required
                type="text"
                v-model="notes[index].note"
                class="
                  focus:outline-none focus:ring-1
                  rounded-lg
                  border border-darkGrey
                  bg-lightGrey
                  w-1/2
                  sm:w-11/12
                  h-10
                  pl-2
                "
              />
            </div>
            <button
              @click="addAnotherNote"
              class="
                rounded-xl
                px-3
                mt-2
                border-2 border-teal
                text-teal
                font-bold
              "
            >
              Add another note
            </button>
          </div>
        </template>
        <template #successButton> Save </template>
      </base-modal>
      <base-modal
        v-model:show="showBlacklistReason"
        @confirm="addLocumToBlacklist"
        :showCancel="true"
        :showConfirm="true"
      >
        <template #title>Enter Reason For Blacklisting</template>
        <template #content>
          <input
            placeholder="Enter Reason:"
            required
            type="text"
            v-model="reasonForBlacklisting"
            class="
              focus:outline-none focus:ring-1
              rounded-lg
              border border-darkGrey
              bg-lightGrey
              w-1/2
              sm:w-11/12
              h-10
              pl-2
            "
          />
        </template>
        <template #successButton> Save </template>
      </base-modal>
    </div>
  </transition>
</template>


<script>
import Swal from "sweetalert2";
import BaseSpinner from "../../../components/ui/baseComponents/BaseSpinner.vue";

export default {
  name: "myStaff",
  component: {
    BaseSpinner,
  },
  data() {
    return {
      fetchUsersPayload: { is_hired: 1, per_page: 12, page: 1 },
      invitePayload: { session: "", locum: "" },
      profiles: [],
      dropdown: false,
      dropdownSecondary: false,
      dropdownId: 0,
      locationFilterExpanded: false,
      roleFilterExpanded: false,
      filtersExpanded: false,
      statusFilterExpanded: false,
      priviledgesFilterExpanded: false,
      showAddSession: false,
      showSelectSession: false,
      sessions: [],
      sessionsResponse: [],
      userInvites: [],
      roles: [],
      locations: [],
      filterLocations: [],
      filterRoles: [],
      refreshProfiles: 0,
      cardsHeading: "Users:",
      showNotes: false,
      notes: [{ note: "" }],
      userId: 0,
      showBlacklistReason: false,
      reasonForBlacklisting: "",
      loaderFlag: false,
      spinnerFlag: false,
      locumProfiles: [],
      pageForProfiles: 1,
      sessionsPage: 1,
      currentUser: null,
    };
  },
  async created() {
    await this.fetchLocumProfiles();
    await this.fetchRoles();
    await this.fetchLocations();
    this.getLocumProfiles();
    this.getRoles();
    this.getLocations();
  },
  methods: {
    toggleSelectAllLocations() {
      if (this.filterLocations.length === this.locations.length) {
        this.filterLocations = [];
      } else {
        this.filterLocations = this.locations.map((location) => location.id);
      }
    },
    toggleSelectAllRoles() {
      if (this.filterRoles.length === this.roles.length) {
        this.filterRoles = [];
      } else {
        this.filterRoles = this.roles.map((role) => role.id);
      }
    },
    locationToggle() {
      console.log("LOCATION:", this.filterLocations);
    },
    roleToggle() {
      console.log("ROles:", this.filterRoles);
    },
    async fetchRoles() {
      try {
        await this.$store.dispatch("ReLocums/fetchRoles");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async fetchLocations() {
      try {
        await this.$store.dispatch("ReLocums/fetchLocations");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getRoles() {
      return this.$store.getters["ReLocums/getRoles"];
    },
    getLocations() {
      this.locations = this.$store.getters["ReLocums/getLocations"];
      return this.locations;
    },
    async sendInvite(sessionId) {
      try {
        this.invitePayload.session = sessionId;
        await this.$store.dispatch(
          "ReLocums/inviteUserToSession",
          this.invitePayload
        );
        this.loaderFlag = false;
        this.userInvites = [...this.userInvites, { ...this.invitePayload }];
        //this.invitePayload = {session: '', locum: ''}
      } catch (error) {
        console.log(error);
      }
    },
    format12(time) {
      let newTime;
      let hours = time.slice(0, 2);
      let minutes = time.slice(3, 5);
      if (hours > 12) {
        newTime = hours % 12;
        newTime += `:${minutes} pm`;
      } else {
        newTime = `${hours}:${minutes} am`;
      }
      return newTime;
    },
    async selectSession(user) {
      this.currentUser = user;
      this.loaderFlag = false;
      this.invitePayload.locum = user.id;
      //const roleId = user.roles[0]?.id
      this.dropdown = false;
      this.dropdownSecondary = false;
      this.showSelectSession = true;

      try {
        await Promise.all([
          this.$store.dispatch("ReLocums/fetchSessions", {
            page: this.sessionsPage,
          }),
          this.$store.dispatch("ReLocums/fetchUserInvites", {
            user: this.currentUser.id,
          }),
        ]);

        this.sessions = this.$store.getters["ReLocums/getSessions"]?.data;
        this.userInvites = this.$store.getters["ReLocums/getUserInvites"];
        this.sessionsResponse = this.$store.getters["ReLocums/getSessions"];
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    async applyFilters(payload = this.fetchUsersPayload) {
      if (this.filterLocations.length > 0) {
        payload.locations = this.filterLocations;
      }
      if (this.filterRoles.length > 0) {
        payload.roles = this.filterRoles;
      }
      if (this.cardsHeading == "Locums:") {
        payload.isLocum = 1;
      }
      if (this.cardsHeading == "Blacklisted Locums:") {
        payload.isLocum = 1;
        payload.is_blacklisted = 1;
      }
      await this.fetchLocumProfiles(payload);
      this.getLocumProfiles();
    },
    async fetchLocumProfiles(payload = this.fetchUsersPayload) {
      this.spinnerFlag = true;
      try {
        await this.$store.dispatch("ReLocums/fetchLocumProfiles", payload);
        this.spinnerFlag = false;
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
    getLocumProfiles() {
      this.locumProfiles = this.$store.getters["ReLocums/getLocumProfiles"];
      this.profiles = this.locumProfiles.data?.users?.data;
      for (let i = 0; i < this.profiles.length; i++) {
        this.profiles[i].active = false;
      }
      this.refreshProfiles++;
      return this.profiles;
    },
    toggleDropdown(id) {
      this.dropdown = !this.dropdown;
      this.dropdownSecondary =
        this.dropdownSecondary === true ? false : this.dropdownSecondary;
      this.dropdownId = id;
    },
    toggleDropdownSecondary(id) {
      this.dropdownSecondary = !this.dropdownSecondary;
      this.dropdownId = id;
    },
    goBack() {
      this.$router.push({ name: "re-locums" });
    },
    async usersFunc() {
      this.dropdown = false;
      this.cardsHeading = "Users:";
      this.fetchUsersPayload = { is_hired: 1, per_page: 12, page: 1 };
      await this.fetchLocumProfiles();
      this.getLocumProfiles();
    },
    async locumsFunc() {
      this.dropdown = false;
      this.cardsHeading = "Locums:";
      (this.fetchUsersPayload = {
        is_hired: 1,
        per_page: 12,
        is_locum: 1,
        is_blacklisted: 0,
        page: 1,
      }),
        await this.fetchLocumProfiles();
      this.getLocumProfiles();
    },
    async blacklistFunc() {
      this.dropdown = false;
      this.cardsHeading = "Blacklisted Locums:";
      (this.fetchUsersPayload = {
        is_hired: 1,
        per_page: 12,
        is_blacklisted: 1,
        page: 1,
      }),
        await this.fetchLocumProfiles();
      this.getLocumProfiles();
    },
    rotateFunc(user) {
      user.active = !user.active;
    },
    addAnotherNote() {
      this.notes.push({
        note: "",
      });
    },
    notesForUserCheck(id) {
      this.userId = id;
      this.showNotes = true;
      this.notes = [{ note: "" }];
    },
    async submitNotes() {
      try {
        await Promise.all([
          this.$store.dispatch("ReLocums/addLocumNotes", {
            locum: this.userId,
            notes: this.notes,
          }),
        ]);
        this.showNotes = false;

        Swal.fire({
          title: "Done",
          text: "Notes Added!",
          icon: "success",
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async removeUserAsLocum(id) {
      try {
        await Promise.all([
          this.$store.dispatch("ReLocums/removeUserAsLocum", {
            user: id,
            locum_status: 0,
          }),
        ]);
        (this.fetchUsersPayload = {
          is_hired: 1,
          per_page: 12,
          is_locum: 1,
          is_blacklisted: 0,
          page: 1,
        }),
          await this.fetchLocumProfiles();
        this.getLocumProfiles();

        Swal.fire({
          title: "Done",
          text: "User Removed as Locum",
          icon: "success",
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async addLocumToBlacklist() {
      try {
        await Promise.all([
          this.$store.dispatch("ReLocums/addLocumToBlacklist", {
            user: this.userId,
            blacklist_reason: this.reasonForBlacklisting,
          }),
        ]);
        this.showBlacklistReason = false;
        (this.fetchUsersPayload = {
          is_hired: 1,
          per_page: 12,
          is_locum: 1,
          is_blacklisted: 0,
          page: 1,
        }),
          await this.fetchLocumProfiles();
        this.getLocumProfiles();

        Swal.fire({
          title: "Done",
          text: "Locum Added To The Blacklist.",
          icon: "success",
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    async removeFromBlacklist(id) {
      try {
        await Promise.all([
          this.$store.dispatch("ReLocums/removeFromBlacklist", {
            locum: id,
          }),
        ]);
        (this.fetchUsersPayload = {
          is_hired: 1,
          per_page: 12,
          is_blacklisted: 1,
          page: 1,
        }),
          await this.fetchLocumProfiles();
        this.getLocumProfiles();

        Swal.fire({
          title: "Done",
          text: "User Removed From Blacklist.",
          icon: "success",
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },

    openBlacklistReasonDialog(id) {
      this.userId = id;
      this.showBlacklistReason = true;
      this.reasonForBlacklisting = "";
    },

    async change(page) {
      this.fetchUsersPayload.page = page;
      await this.fetchLocumProfiles(this.fetchUsersPayload);
      this.getLocumProfiles();
    },

    async changeSessionsPage(page) {
      this.sessionsPage = page;
      await this.selectSession(this.currentUser);
    },
  },
};
</script>

<style>
.sidebar {
  min-width: 350px;
  max-width: 350px;
  min-height: 92.5vh;
}
.filter-dropdown-icon {
  transition: transform 0.5s ease;
}

.filter-up {
  transform: rotate(180deg);
}
.front-and-back {
  position: relative;
  height: 430px;
  transition: transform 1s;
  transform-style: preserve-3d;
}
.rotateClass {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}
.front {
  padding-left: 0.4rem;
  align-items: center;
  text-align: center;
  backface-visibility: hidden;
}

.back {
  padding-right: 2rem;
  backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}

.clickClass {
  color: black !important;
  font-weight: bold !important;
}

.notesList {
  max-height: 320px;
  overflow-y: scroll;
}
</style>